import React, { Component, useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import Gong from "./Gong";
import Timebox from "./Timebox";

const MAX_VOL = 0.5;

function App(props) {
    const [vol, setVol] = useState(MAX_VOL);

    
    useEffect(() => {
        ['win', 'lose'].forEach(id => {
            const audio = document.getElementById(id);
            audio.volume = vol;
        });
    }, [vol]);
    return (
      <div>
        <BrowserRouter>
          <header>
            <Link to="/gong">Gong</Link>
            <Link to="/timebox">Timebox</Link>
            <div>
              <input
                type="range"
                id="start"
                name="volume"
                min="0"
                max="10"
                onChange={({ target: { value } }) =>
                  setVol((value * MAX_VOL) / 10)
                }
              />
              <label htmlFor="volume">Volume</label>
            </div>
          </header>
          <Route path="/gong">
            <Gong />
          </Route>
          <Route path="/timebox">
            <Timebox />
          </Route>
        </BrowserRouter>
      </div>
    );
}

export default App;
