import React, { Component } from "react";
import "./App.css";
import * as workerTimers from "worker-timers";
import { formatTimeRemainingDisplay } from "./utilities/formatters";
import { MINUTE, SECOND, LS_KEY, COMPOSING_MSG } from "./constants";
import { sendIntervalEndNotif, NotifEnableButton } from "./NotifEnableButton";

// setGlobal({
//   secondsLeft: 15 * 60,
//   periodLength: 15 * 60,
//   lengthInputVal: '15',
//   isRunning: false,
//   currTask: '',
//   prevTasks: [],
//   streakLength: 0,
//   currTimeout: null
// })

const getCurrTime = () => new Date().toLocaleString();
const setTitle = (val) => (window.document.title = val);
const DEFAULT_PERIOD_LENGTH = 10 * 60;

let failureTimeout = null;

class Timebox extends Component {
  constructor(props) {
    super(props);
    const loadedState = this.loadState();
    this.state = loadedState
      ? Object.assign(loadedState, { isRunning: false, currTimeout: null })
      : {
          isLoopingFailure: false,
          secondsLeft: DEFAULT_PERIOD_LENGTH,
          periodLength: DEFAULT_PERIOD_LENGTH,
          lengthInputVal: "10",
          isRunning: false,
          currTask: "",
          prevTasks: [],
          streakLength: 0,
          currTimeout: null,
          //TODO - lt: vvv remove
          //muted: window.location.origin.includes("localhost")
        };
  }

  tick = () => {
    const newVal = this.state.secondsLeft - 1;
    if (newVal === 60) {
      this.playSound("one-min-left");
    }
    this.setState({ secondsLeft: newVal }, this.saveState);
    setTitle(formatTimeRemainingDisplay(newVal));
  };

  playSound = (soundStr) => {
    if (!this.state.muted) {
      const audio = document.getElementById(soundStr);
      audio.currentTime = 0;

      audio.play();
    }
  };

  startTimer = () => {
    if (this.state.isLoopingFailure) {
      this.stopLoopingFailure();
    }
    if (this.state.secondsLeft > 0) {
      this.tick();
      const timeout = workerTimers.setTimeout(this.startTimer, 1000);
      this.setState({ currTimeout: timeout, isRunning: true });
    } else {
      this.endPeriod(false);
    }
  };

  stopLoopingFailure = () => {
    if (this.state.isLoopingFailure) {
      workerTimers.clearTimeout(failureTimeout);
      failureTimeout = null;
      this.setState({ isLoopingFailure: false });
    }
  };

  startLoopingFailure = () => {
    this.setState({ isLoopingFailure: true });
    this.playSound("lose");
    sendIntervalEndNotif();
    failureTimeout = workerTimers.setTimeout(this.startLoopingFailure, 2500);
  };

  endPeriod = (succeeded) => {
    const { isComposing, currTask } = this.state;
    if (this.state.isRunning) {
      this.stopTimer();
      const doneTask = this.state.currTask;
      const taskObj = { endTime: getCurrTime(), task: doneTask, succeeded };
      const newSecondsLeftVal = Number(this.state.lengthInputVal) * 60;

      this.setState({
        secondsLeft: newSecondsLeftVal,
        currTask: "",
        prevTasks: this.state.prevTasks.concat([taskObj]),
      });
      setTitle(formatTimeRemainingDisplay(newSecondsLeftVal));
      if (succeeded) {
        this.playSound("win");
      } else {
        this.startLoopingFailure();
      }
      // if (!this.state.isComposing) {
      //   this.setState({
      //     isComposing: true,
      //     secondsLeft: 60
      //   }, this.startTimer);

      // } else {
      this.setState({ isComposing: false });
      // }
    }
  };

  lengthChange = ({ target: { value } }) =>
    this.setState({ lengthInputVal: value, secondsLeft: Number(value) * 60 });
  taskChange = ({ target: { value } }) => this.setState({ currTask: value });

  loadState = () => {
    const prevState = localStorage.getItem(LS_KEY);
    return prevState ? JSON.parse(prevState) : null;
  };
  saveState = () => localStorage.setItem(LS_KEY, JSON.stringify(this.state));

  stopTimer = () => {
    workerTimers.clearTimeout(this.state.currTimeout);
    this.setState({ currTimeout: null, isRunning: false });
  };

  clearTasks = () => {
    this.setState({ prevTasks: [] });
  };

  componentDidUpdate() {
    this.saveState();
  }

  render() {
    const {
      prevTasks,
      isRunning,
      lengthInputVal,
      currTask,
      muted,
      isComposing,
    } = this.state;
    const secsLeft = this.state.secondsLeft;
    console.log(muted);
    return (
      <div className="App">
        <NotifEnableButton />
        <div>
          Mute:{" "}
          <input
            type="checkbox"
            checked={muted}
            onChange={(e) => this.setState({ muted: e.target.checked })}
          />
        </div>
        <div>
          Curr Task:{" "}
          <input
            disabled={isRunning && !isComposing}
            type="text"
            value={currTask}
            onChange={this.taskChange}
          />
        </div>
        {isRunning ? (
          <div>Time Left: {formatTimeRemainingDisplay(secsLeft)}</div>
        ) : (
          <div>
            Time Left:{" "}
            <input
              value={lengthInputVal}
              type="number"
              onChange={this.lengthChange}
            />
          </div>
        )}
        <button disabled={isRunning} onClick={this.startTimer}>
          Start
        </button>
        <button onClick={this.stopTimer}>Pause</button>
        <button disabled={!isRunning} onClick={() => this.endPeriod(true)}>
          Done
        </button>
        <button disabled={isRunning} onClick={this.clearTasks}>
          Clear Prev Tasks
        </button>
        <div>Streak: {getStreakLn(prevTasks)}</div>
        {prevTasks
          .slice()
          .reverse()
          .map(({ task: t, endTime, succeeded }, i) => {
            const className = succeeded ? "win" : "lose";
            return (
              <p className={className} key={(t || "") + i}>
                {endTime} - {t}
              </p>
            );
          })}
      </div>
    );
  }
}

const getStreakLn = (tasks) => {
  let streakLn = 0,
    idx = tasks.length - 1;
  if (!tasks.length || !tasks[idx].succeeded) return 0;
  while (idx > -1 && tasks[idx].succeeded) {
    streakLn++;
    idx--;
  }
  return streakLn;
};

export default Timebox;
