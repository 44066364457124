import React, {Component} from 'react'
import {
    BrowserRouter,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import * as workerTimers from 'worker-timers';
import {formatTimeRemainingDisplay} from './utilities/formatters';
import { MINUTE, SECOND, LS_KEY, COMPOSING_MSG } from './constants';

const getCurrTime = () => (new Date()).toLocaleString();
const setTitle = val => {
    //TODO(lt): rm?
    return;
    // window.document.title = val;
}

const randIntBetween = (inclMin, exclMax) => {
    const range = exclMax - inclMin;
    return Math.floor((Math.random() * range)) + inclMin;
}

class Gong extends Component {

constructor(props) {
  super(props);
  this.state = {
    // secondsLeft: randIntBetween(3, 11) * 60,
    secondsLeft: 1,
    periodLength: 15 * 60,
    lengthInputVal: '15',
    isBreak: false,
    isRunning: false,
    currTask: '',
    prevTasks: [],
    streakLength: 0,
    currTimeout: null,
    //TODO - lt: vvv remove 
    //muted: window.location.origin.includes("localhost")
  }
}

  tick = () => {

    const newVal = this.state.secondsLeft - 1;
    this.setState({secondsLeft: newVal});
    setTitle(formatTimeRemainingDisplay(newVal));
  }

  playSound = (succeeded) => {
    if (!this.state.muted) {
      const audio = document.getElementById(succeeded ? 'win' : 'lose');
      console.log(audio.volume)
      audio.play();
    }
  }

  startTimer = () => {
    if (this.state.secondsLeft > 0) {
      this.tick();
      const timeout = workerTimers.setTimeout(this.startTimer, 1000);
      this.setState({currTimeout: timeout, isRunning: true});
    } else {
      this.endPeriod();
    }
  }

//   randInterlude = () => {
//       //rand between 3 and 10 mins
//     return MINUTE * randIntBetween(3, 11); // 3 to 10 inclusive
//   }




  endPeriod = () => {
    const {isBreak, min, max, rest} = this.state;

    let newSecondsLeftVal = isBreak ? randIntBetween(min, max + 1) * 60 : rest * 60;

      this.setState({ 
        secondsLeft: newSecondsLeftVal, 
        isRunning: true,
        isBreak: !isBreak
      });
      setTitle(formatTimeRemainingDisplay(newSecondsLeftVal));
      this.playSound(isBreak);
      this.startTimer();

  }

  setTimeVal = ({target: {name, value}}) => {
      this.setState({[name]: Number(value)});
  }



  render() {
    const {prevTasks, isRunning,lengthInputVal, currTask, muted, isComposing} = this.state;
    const secsLeft = this.state.secondsLeft;
    return (
      <div className="App">
       <div>Time Left: {formatTimeRemainingDisplay(secsLeft)}</div>
       <button disabled={isRunning} onClick={this.startTimer}>Start</button>
       <input placeholder="min..." type="number" name="min" onChange={this.setTimeVal}/>
       <input placeholder="max..." type="number" name="max" onChange={this.setTimeVal}/>
       <input placeholder="rest..." type="number" name="rest" onChange={this.setTimeVal}/>
      </div>
    );
  }
}


export default Gong;
