const formatSecondsPart = totalSeconds => {
    const secondsPart = (totalSeconds % 60).toString();
    return secondsPart.length === 2 ? secondsPart : secondsPart.padStart(2, '0');
}
  
export const formatTimeRemainingDisplay = secsLeft => `${Math.floor(secsLeft / 60)}:${formatSecondsPart(secsLeft)}`;

export const formatTimeStr = (msIntOrISOStr) => {
  return new Date(msIntOrISOStr).toLocaleTimeString("en-US", {
    hour12: false,
    timeStyle: "short",
  });
};