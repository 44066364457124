import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./App";


ReactDOM.render(<App />, document.getElementById('root'));

navigator.serviceWorker
  //TODO(lt): vvv this name is too overridden by CRA
  // .register(
  //   process.env.NODE_ENV === "development"
  //     ? "/sw-local.js"
  //     : "/service-worker.js"
  // )
  .register("/sw-local.js")
  .then(function() {
    return navigator.serviceWorker.ready;
  })
  .then(function(registration) {
    console.log(registration); // service worker is ready and working...
  });
